
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #B97375;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #F4EDEA;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }



$theme-colors: (
  "primary": #B97375
);

  @import "node_modules/bootstrap/scss/bootstrap";

body {
    background-color: var(--light);
    font-family: 'Oswald', sans-serif;
}

body::-webkit-scrollbar {
  width: 5px;
}
 
body::-webkit-scrollbar-track {
  background-color: transparent;
}
 
body::-webkit-scrollbar-thumb {
  background-color: #B97375;
  outline: none;
}

header{
  width: 100%;
  position: absolute;
  padding: 0px 30px 0px 30px ;
  z-index: 2;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a{
    color: black;
    text-decoration: none;
  }:hover{

    color: black;
  }
}

p{
  font-family: 'Roboto', sans-serif;
}

.background{
  position: absolute;
  z-index: 1;
  height: 100vh;
  z-index: -2;
  width: 100%;
  .top{
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .bottom{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100% !important;
  }
}

.btn-primary{
  color: white !important;
}

::selection {
  background: #ffb7b7; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #ffb7b7; /* Gecko Browsers */
}


.transitition{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 200;
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  li{
    height: 0vh;
    width: 20%;
    background-color: #cccccc;
  }
  li:nth-child(2n){
    background-color: #B97375;
  }
}

.first{
  padding-top: 100px;
}


.wrappermodal{
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0vh;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.638);
  width: 100%;
}